// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alternatives-js": () => import("./../../../src/pages/alternatives.js" /* webpackChunkName: "component---src-pages-alternatives-js" */),
  "component---src-pages-cards-js": () => import("./../../../src/pages/cards.js" /* webpackChunkName: "component---src-pages-cards-js" */),
  "component---src-pages-deps-js": () => import("./../../../src/pages/deps.js" /* webpackChunkName: "component---src-pages-deps-js" */),
  "component---src-pages-domains-js": () => import("./../../../src/pages/domains.js" /* webpackChunkName: "component---src-pages-domains-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layers-js": () => import("./../../../src/pages/layers.js" /* webpackChunkName: "component---src-pages-layers-js" */),
  "component---src-pages-public-stack-tools-js": () => import("./../../../src/pages/public-stack-tools.js" /* webpackChunkName: "component---src-pages-public-stack-tools-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-responsible-business-model-canvas-js": () => import("./../../../src/pages/responsible-business-model-canvas.js" /* webpackChunkName: "component---src-pages-responsible-business-model-canvas-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-domain-js": () => import("./../../../src/templates/domain.js" /* webpackChunkName: "component---src-templates-domain-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */),
  "component---src-templates-use-case-js": () => import("./../../../src/templates/use-case.js" /* webpackChunkName: "component---src-templates-use-case-js" */)
}

